import {
    Edit,
    EditProps,
    DateTimeInput,
    FileField,
    FileInput,
    Loading,
    required,
    SelectInput,
    SimpleForm,
    TextInput
} from 'react-admin';
import {useGetList} from "ra-core";
import React, {useState} from 'react';

export const ExperimentsEdit = (props: EditProps) => {
    const {
        data,
        isLoading,
        error
    } = useGetList<InstrumentFrontend>('instruments', {
        pagination: {
            page: 1,
            perPage: 1000
        },
        sort: {field: "", order: ""}
    });

    const [currentTimeStamp] = useState(new Date());

    if (isLoading) return <Loading/>;
    if (error) return (<p>Error in loading instruments</p>);

    return (
        <Edit {...props} title={"Experiment update"} redirect="list">
            <SimpleForm>
                {/*[
  {
    "src": "extra_file_1",
    "title": "The Extra File Number One"
  },
  {
    "rawFile": {
      "path": "proximus fiber.rtf"
    },
    "src": "blob:http://localhost:3000/83196caf-d284-4795-8ce2-782f6b2c762b",
    "title": "proximus fiber.rtf"
  }
] when the second file has been deleted and the first one added.
A file id might also be a good plan so we do not have to match on title*/}
                <FileInput source="extraFiles" label="Extra Files"
                           multiple
                >
                    <FileField source="title" title="title" />
                </FileInput>

            </SimpleForm>
        </Edit>
    );

}