import React from 'react';
import {
    Create,
    CreateProps,
    FileField,
    FileInput,
    required,
    SimpleForm,
    TextInput
} from 'react-admin';


export const InstrumentsCreate = (props: CreateProps) => (
    <Create {...props} redirect="list">
        <SimpleForm>
            <TextInput source="name" validate={required()}/>
            <FileInput source="script" label="Python scripts" validate={required()} accept={".py"}>
                <FileField source="src" title="title" />
            </FileInput>
        </SimpleForm>
    </Create>
);