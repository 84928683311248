import jsonServerProvider from "ra-data-json-server";
import {
    CreateParams,
    CreateResult,
    DataProvider,
    fetchUtils,
    LegacyDataProvider,
    UpdateParams,
    UpdateResult
} from 'react-admin';

export const backendUrl = process.env.REACT_APP_ADMIN_SERVER;

const httpClient = (url: string, options: fetchUtils.Options | undefined) => {
    if (options !== undefined && !options.headers) {
        options.headers = new Headers({Accept: 'application/json'});
    }
    return fetchUtils.fetchJson(url, options);
};
const jsonProvider = jsonServerProvider(backendUrl, httpClient)

export type RetrievePlotProps = {
    containsText: string;
    variable: string;
    attribute: string;
    grouping: string;
}

type RawFileBlob = {
    rawFile: Blob;
    title: string;
};

type TitleOnly = {
    src: string;
    title: string;
};

type FrontendFileContent = string | ArrayBuffer | null;

type FrontendFile = {
    content: FrontendFileContent;
    title: string;
}


const dataProvider: DataProvider | LegacyDataProvider = {
    ...jsonProvider,
    async retrievePlots(retrievePlotProps: RetrievePlotProps) {

        const resource = 'plots';
        const url = `${backendUrl}/${resource}?`;
        let typedUrl = new URL(url);
        //TODO we can really do this better  I guess. Also, weird props man
        typedUrl.searchParams.append('containsText', retrievePlotProps.containsText)
        typedUrl.searchParams.append('variable', retrievePlotProps.variable)
        typedUrl.searchParams.append('attribute', retrievePlotProps.attribute)
        typedUrl.searchParams.append('grouping', retrievePlotProps.grouping)
        return httpClient(typedUrl.href, {}).then(({json}) => ({data: json}));
    },
    create: (resource: string, params: CreateParams) => {
        if (resource === 'instruments') {
            let base64d = convertFileToBase64(params.data["script"])
            return base64d.then(b64file =>
                jsonProvider.create(resource, {
                    ...params,
                    data: {
                        ...params.data,
                        content: b64file,
                    },
                })
            );
        }
        if (resource === 'experiments') {
            const excelSheets: RawFileBlob[] = params.data.excelSheet.filter(
                (p: { rawFile: any; }) => p.rawFile instanceof File
            );
            const extraPictures: RawFileBlob[] = params.data.extraPicture ? params.data.extraPicture.filter(
                (p: { rawFile: any; }) => p.rawFile instanceof File
            ) : [];

            const annotationSheet: RawFileBlob = params.data.annotationSheet

            return translateFiles(excelSheets, extraPictures, annotationSheet, params)
        }
        return jsonProvider.create(resource, params);
    },
    update: (resource: string, params: UpdateParams) => {
        const filteredNewFiles = params.data.extraFiles.filter(
            (p: { rawFile: any; }) => p.rawFile instanceof File
        );
        console.log("new files")
        console.log(filteredNewFiles)
        console.log("end of new files")
        const existingFiles = params.data.extraFiles.filter(
            (p: { rawFile: any; }) => p.rawFile === undefined
        );
        return translateNewFilesOnly(existingFiles, filteredNewFiles, params)

    }
};

async function translateFiles(excelSheets: RawFileBlob[], extraPictures: RawFileBlob[], annotationSheet: RawFileBlob, params: CreateParams): Promise<CreateResult<any>> {
    const transformedExcelSheets = await Promise.all(excelSheets.map(convertFileToBase64))
    const annotationBase64 = await convertFileToBase64(annotationSheet)
    const transformedExtraPictures = await Promise.all(extraPictures.map(convertFileToBase64))
    return createJson('experiments', params, transformedExcelSheets, transformedExtraPictures, annotationBase64)
}

async function translateNewFilesOnly(existingFiles: TitleOnly[], newFiles: RawFileBlob[], params: UpdateParams): Promise<UpdateResult<any>> {
    const transformedExtraPictures = await Promise.all(newFiles.map(convertFileToBase64))

    return jsonProvider.update('experiments', {
        ...params,
        data: {
            id: params.data.id,
            newFiles: transformedExtraPictures,
            existingFiles: existingFiles
        }
    });
}

async function createJson(resource: string, params: CreateParams, excelSheets: FrontendFile[], extraPictures: FrontendFile[], annotationSheet: FrontendFile): Promise<CreateResult<any>> {

    return jsonProvider.create(resource, {
        ...params,
        data: {
            ...params.data,
            annotationSheet: annotationSheet,
            excelSheets: excelSheets,
            extraPictures: extraPictures
        },
    })
}

async function convertFileToBase64(file: RawFileBlob): Promise<FrontendFile> {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file.rawFile);

        reader.onload = () => resolve({
            content: reader.result,
            title: file.title,
        });
        reader.onerror = reject;
    });
}

export default dataProvider;
