import {Create, CreateProps, SimpleForm, TextInput, NumberInput, BooleanInput, required} from 'react-admin';
import React from 'react';

enum TypeOfField {
    INTFIELD, FLOATFIELD, STRINGFIELD, BOOLFIELD
}

type FieldDescription = {
    name: string,
    defaultValue: string,
    typeOfField: TypeOfField
}

let defaultFields: FieldDescription [] = [
    {name: "test1", defaultValue: "", typeOfField: TypeOfField.STRINGFIELD},
    // {name: "test2", defaultValue: "1", typeOfField: TypeOfField.INTFIELD},
    {name: "test3", defaultValue: "true", typeOfField: TypeOfField.BOOLFIELD},
    // {name: "test4", defaultValue: "1.03", typeOfField: TypeOfField.FLOATFIELD},
]

function toComponent(fieldDescription: FieldDescription): JSX.Element {
    switch(fieldDescription.typeOfField){
        case TypeOfField.INTFIELD:
            return <NumberInput
                source={fieldDescription.name}
                label={fieldDescription.name}
                defaultValue={fieldDescription.defaultValue}
                validate={required()}
            />
        case TypeOfField.FLOATFIELD:
            return <NumberInput
                source={fieldDescription.name}
                label={fieldDescription.name}
                options={{ maximumFractionDigits: 2 }}
                validate={required()}
                defaultValue={fieldDescription.defaultValue}/>
        case TypeOfField.STRINGFIELD:
            return <TextInput
                source={fieldDescription.name}
                label={fieldDescription.name}
                validate={required()}
                defaultValue={fieldDescription.defaultValue}/>
        case TypeOfField.BOOLFIELD:
            return <BooleanInput
                source={fieldDescription.name}
                label={fieldDescription.name}
                validate={required()}
                defaultValue={fieldDescription.defaultValue}/>;

    }

}

export const TestCreate = (props: CreateProps) => {

    return (
        <Create {...props} redirect="list">
            <SimpleForm>
                <React.Fragment>
                    {defaultFields.map(i => toComponent(i))}
                </React.Fragment>
            </SimpleForm>
        </Create>
    );

}