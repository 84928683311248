import React from 'react';
import {Show, ShowProps, SimpleShowLayout, TextField} from 'react-admin';
import {PythonCode} from "./pythonCode";
import Prism from "prismjs";


type InstrumentShowFrontend = {
    id : string,
    name : string,
    script : string,
}

export const InstrumentsShow = (props: ShowProps<InstrumentShowFrontend>) => {
    setTimeout(() => Prism.highlightAll(), 0)

    return (<Show title="Instrument detail" {...props}>
        <SimpleShowLayout>
            <TextField source="name"/>
            <PythonCode source="content"/>
        </SimpleShowLayout>
    </Show>);
}
