import Plot from "react-plotly.js";
import React from "react";

const colors = [
    getComputedStyle(document.documentElement).getPropertyValue('--color-for-plot-1'),
    getComputedStyle(document.documentElement).getPropertyValue('--color-for-plot-5'),
    getComputedStyle(document.documentElement).getPropertyValue('--color-for-plot-2'),
    getComputedStyle(document.documentElement).getPropertyValue('--color-for-plot-4'),
    getComputedStyle(document.documentElement).getPropertyValue('--color-for-plot-3'),
]

export class PlotDescription {
    readonly position: string;
    readonly legend: string;
    readonly colorClass: number;
    readonly x: number[];
    readonly y: number[];

    constructor(position: string, legend: string, colorClass: number, x: number[], y: number[]) {
        this.position = position;
        this.legend = legend;
        this.colorClass = colorClass;
        this.x = x;
        this.y = y;
    }
}

export function createPlot(plots: PlotDescription[]) {

    let legendsEncountered: string[] = [];

    const myData = plots.map(plot => {
        let showLegend = true
        if (legendsEncountered.includes(plot.legend)) {
            showLegend = false
        } else {
            legendsEncountered.push(plot.legend)
        }
        let result: Plotly.Data = {
            type: 'scatter',
            x: plot.x,
            y: plot.y,
            mode: 'lines',
            line: {
                color: colors[plot.colorClass]
            },
            name: plot.legend,
            legendgroup: plot.legend,
            showlegend: showLegend

        }
        return result
    })
    const plotText = "plot"

    return (
        <Plot
            className={"plot-item"}
            data={myData}
            layout={{
                title: plotText,
                showlegend: true,
                xaxis: {
                    title: {
                        text: 'Time'
                    }
                },
                yaxis: {
                    title: {
                        text: 'Value'
                    }
                }
            }}
        />);
}

class ShowPlotProps {
    readonly plotValues: PlotDescription[];

    constructor(plotValues: PlotDescription[]) {
        this.plotValues = plotValues;
    }
}

export const ShowPlot = (props: ShowPlotProps) => {
    return <div
        className="plot-list"
    >
        {props.plotValues && props.plotValues.length > 0 ? createPlot(props.plotValues) : null}
    </div>;
}