import {
    ArrayField,
    Datagrid,
    DateField,
    FileField,
    NumberField,
    ReferenceField,
    Show,
    ShowProps,
    SimpleShowLayout,
    TextField
} from "react-admin";
import {ZipDownload} from "./zipdownload";
import {StatusField} from "./statusField";
import React from "react";


export const ExperimentsShow = (props: ShowProps) => {
    return (
        <Show title="Experiment Detail" {...props}>
            <SimpleShowLayout>
                <TextField source="name"/>
                <TextField source="comment"/>
                <ReferenceField source="instrument" reference="instruments"
                                link="show">
                    <TextField source="name"/>
                </ReferenceField>
                <DateField source="dateTime.iMillis" label={"Experiment Time"} showTime/>

                <fieldset>
                    <label>Parameter info</label>
                    <ArrayField label={"Parameters"} source={"parameterValues"}>
                        <Datagrid>
                            <TextField source={"name"} label={"Parameter name"}/>
                            <TextField source={"value"} label={"Selected value for this run"}/>
                            <TextField source={"defaultValue"} label={"Default value"}/>
                            <TextField source={"type"} label={"Parameter type"}/>
                        </Datagrid>
                    </ArrayField>
                </fieldset>
                <fieldset>
                    <label>Standard out and standard error</label>
                    <ArrayField label={"Run Results"} source={"runResult"}>
                        <Datagrid>
                            <NumberField source={"exitCode"}/>
                            <TextField source={"stdOut"}/>
                            <TextField source={"stdErr"}/>
                        </Datagrid>
                    </ArrayField>
                </fieldset>
                <StatusField source={"status"} label={"Status"} showLabel={true}/>
                <ZipDownload identifier={"id"}/>
                <FileField source="extraFiles" title={"title"} src={"http://koekoek.merel.com/78"}/>

            </SimpleShowLayout>
        </Show>
    );
}