import {Datagrid, List, ListProps, TextField} from 'react-admin';
import {StatusField} from "./statusField";
import {useEffect, useRef} from "react";
import {useRefresh} from 'ra-core';

export const refreshInterval = process.env.REACT_APP_REFRESH_INTERVAL;

function useInterval(callback: (() => void), delay: number) {
    const savedCallback = useRef<(() => void)>();

    useEffect(() => {
        savedCallback.current = callback;
    });

    useEffect(() => {
        function tick() {
            let functionInCallback = savedCallback.current
            if (functionInCallback) {
                functionInCallback()
            }
        }

        let id = setInterval(tick, delay);
        return () => {
	  console.log('KASPER removing interval');
	  clearInterval(id);
	  }
      }
    , []);
}

function calculateRefreshInterval(r: string | undefined): number {
    if (r) {
        return parseInt(r, 10) * 1000
    } else {
        return 300 * 1000
    }
}

export const ExperimentsList = (props: ListProps) => {
    let calculatedRefreshInterval = calculateRefreshInterval(refreshInterval)
    console.log("refreshing every " + calculatedRefreshInterval)
    const refresh = useRefresh()
    useInterval(() => {
        console.log('KASPER REFRESH')
        refresh()
    }, calculatedRefreshInterval)


    return (
        <List {...props} exporter={false}>
            <Datagrid rowClick="show">
                <TextField source="name" label={"Name"}/>
                <TextField source="comment"/>
                <StatusField source={"status"} label={"Status"} showLabel={false}/>
            </Datagrid>
        </List>
    );
}
