// in profile.js
import React, {useCallback, useMemo, useState} from "react";
import {
    FormDataConsumer,
    required,
    SaveContextProvider,
    SelectInput,
    SimpleForm,
    TextInput,
    Title,
    useDataProvider,
    useNotify
} from "react-admin";

//stylesheet
import "./custom.css"
import {PlotDescription, ShowPlot} from "./ShowPlot";
import {AvailableVariableInput} from "./AvailableVariableInput";
import {AvailableAttributeInput} from "./AvailableAttributeInput";
import {OnSuccessData} from "../DataProvider/OnSuccess";
import {RetrievePlotProps} from "../DataProvider/dataProvider";


export const PlotPage = () => {

    const dataProvider = useDataProvider();
    const notify = useNotify();
    const [saving, setSaving] = useState<boolean>();
    const [plotValues, setPlots] = useState<PlotDescription[]>([])

    const handleSave = useCallback((values: RetrievePlotProps) => {
        setSaving(true);
        dataProvider.retrievePlots(
            values
        ).then((data: OnSuccessData) => {
            setSaving(false);
            setPlots(data.data);
        }).catch(() => {
            setSaving(false);
        });
    }, [dataProvider, notify]);

    const saveContext = useMemo(() => ({
        save: handleSave,
        saving
    }), [saving, handleSave]);

    const paramsRecord = {}

    const grouping =
        [
            {id: "average", name: "Average"},
            {id: "no_grouping", name: "No grouping"}
        ]


    return (
        <div>
            <Title title="Plots" />
            <SaveContextProvider value={saveContext}>
                <SimpleForm record={paramsRecord} title={"Plots"}
                     >
                    <TextInput source={"containsText"}
                               label={"Comment or title contains text, use * for everything"}/>

                    <FormDataConsumer subscription={{values: true}}>
                        {({formData, ...rest}) => {
                            if (formData.containsText) {

                                return <AvailableVariableInput source="variable" {...rest}
                                                               link={formData.containsText}
                                                               validate={required()}/>
                            } else {
                                return null;
                            }
                        }
                        }
                    </FormDataConsumer>

                    <FormDataConsumer subscription={{values: true}}>
                        {({formData, ...rest}) => {
                            if (formData.containsText) {

                                return <AvailableAttributeInput source="attribute" {...rest}
                                                                link={formData.containsText}
                                                                validate={required()}/>
                            } else {
                                return null;
                            }
                        }
                        }
                    </FormDataConsumer>
                    <SelectInput choices={grouping}
                                 source={"grouping"} validate={required()}/>

                </SimpleForm>
            </SaveContextProvider>
            {!saving ? <ShowPlot plotValues={plotValues}/> : null}
        </div>
    );
};
