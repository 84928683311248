import {useGetList} from "ra-core";
import {Error, Loading, required, SelectInput, SelectInputProps} from "react-admin";
import React from "react";

type AvailableAttributeFronted = {
    id:string, name : string
}

type AvailableAttributeInputParams = JSX.IntrinsicAttributes & SelectInputProps & {link:string};
export const AvailableAttributeInput = (props: AvailableAttributeInputParams) => {
    const {
        data: availableAttributes,
        isLoading: loadingAvailableAttributes,
        error: errorAvailableAttributes
    } = useGetList<AvailableAttributeFronted>('available_attributes',
        {
            pagination: {
                page: 1,
                perPage: 1000
            }
            , sort: {
                field: "", order: ""
            }
            ,
            filter : props.link
        }
        );

    if (loadingAvailableAttributes) return <Loading/>;
    if (errorAvailableAttributes) return (<p>Could not load available attributes</p>);
    return (<SelectInput choices={availableAttributes} {...props} />);

}