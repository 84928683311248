import React, {FC, useEffect} from "react"
import "../css/prism.css"
import Prism from "prismjs"
import {Buffer} from "buffer";
import {TextFieldProps} from "react-admin";
import {useRecordContext} from "ra-core";

require('prismjs/components/prism-python');

type ConstructorParams = TextFieldProps & { source: string };

export const PythonCode: FC<ConstructorParams> = props => {
    useEffect(() => {
        setTimeout(() => Prism.highlightAll(), 0)
    }, [])
    console.log(props)
    const record = useRecordContext(props);
    let coded = record ? record[props.source] : null;
    let content = coded["content"]
    const decoded = conditionalStripBase64Header(content);
    return (
        <pre><code className="language-python">
                {decoded}
    </code></pre>
    )
}

function conditionalStripBase64Header(content: string): string {
    if (content.startsWith('data:text/x-python;base64')) {
        return Buffer.from(content.split(",")[1], 'base64').toString();
    } else {
        return Buffer.from(content, 'base64').toString()
    }
}