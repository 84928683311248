import {
    BooleanInput,
    Create,
    CreateProps,
    DateTimeInput,
    FileField,
    FileInput,
    FormDataConsumer,
    Loading,
    NumberInput,
    required,
    SelectInput,
    SimpleForm,
    TextInput
} from 'react-admin';
import {useGetList} from "ra-core";
import React, {ReactElement, useState} from 'react';
import {Box} from "@mui/material";

function findDocstring(id: string, data: InstrumentFrontend[]): string[] {
    const instrumentFound = data.find(val => val.id == id)
    return instrumentFound ? instrumentFound.docString.split("\n") : ["No script with id found"]
}

function findParams(id: string, data: InstrumentFrontend[]): ParameterDescription[] {
    const instrumentFound = data.find(val => val.id == id)
    return instrumentFound ? instrumentFound.parameters : []
}

function createInputElement(parameterDescription: ParameterDescription): ReactElement {
    if (parameterDescription.type == "str") {
        return (<TextInput label={"Parameter " + parameterDescription.name}
                           source={"parameters." + parameterDescription.id}
                           defaultValue={parameterDescription.default} validate={required()}/>);
    }
    if (parameterDescription.type == "int") {
        return (<NumberInput label={"Parameter " + parameterDescription.name}
                             source={"parameters." + parameterDescription.id}
                             defaultValue={parameterDescription.default} validate={required()}/>);
    }
    if (parameterDescription.type == "bool") {
        return (<BooleanInput label={"Parameter " + parameterDescription.name}
                              source={"parameters." + parameterDescription.id}
                              defaultValue={parameterDescription.default ? parameterDescription.default.toLowerCase() == "true" : false}
                              validate={required()}/>);
    }
    if (parameterDescription.type == "float") {
        return (<NumberInput step={0.01} label={"Parameter " + parameterDescription.name}
                             source={"parameters." + parameterDescription.id}
                             defaultValue={parameterDescription.default} validate={required()}/>);
    }
    return <div>Unknown type</div>
}


class AsideProps {
    readonly data: InstrumentFrontend[];

    constructor(data: InstrumentFrontend[]) {
        this.data = data;
    }
}


const Aside = (props: AsideProps) => (
    <Box sx={{margin: '1em'}}>
        <FormDataConsumer subscription={{values: true}}>
            {({formData, ...rest}) => {
                if (formData.instrument) {
                    return <div>{props.data ? findDocstring(formData.instrument, props.data).map(val =>
                        <p>{val}</p>) : null}</div>
                } else {
                    return null;
                }
            }
            }
        </FormDataConsumer>
    </Box>
);


export const ExperimentsCreate = (props: CreateProps) => {
    const {
        data,
        isLoading,
        error
    } = useGetList<InstrumentFrontend>('instruments', {
        pagination: {
            page: 1,
            perPage: 1000
        },
        sort: {field: "", order: ""}
    });

    const [currentTimeStamp] = useState(new Date());

    // const [currentSelectedScript, ] = useState("");

    if (isLoading) return <Loading/>;
    if (error) return (<p>Error in loading instruments</p>);

    return (
        <Create {...props} redirect="list"
            // aside={<Aside data={[]}/>}
        >
            <SimpleForm>
                <TextInput source={"name"} label={"Name"} validate={required()}/>
                <TextInput multiline source={"comment"} label={"Comments"}/>
                <FileInput source="excelSheet" label="Input Sheets" validate={required()} multiple
                           accept=".xls,.xlsx,.csv">
                    <FileField source="src" title="title"/>
                </FileInput>
                <FileInput source="annotationSheet" label="Annotation Sheet" validate={required()}
                           accept=".xls,.xlsx,.csv">
                    <FileField source="src" title="title"/>
                </FileInput>
                <FileInput source="extraPicture" label="Extra pictures"
                           multiple>
                    <FileField source="src" title="title"/>
                </FileInput>

                <SelectInput source={"instrument"}
                             choices={data}
                             validate={required()}/>

                <FormDataConsumer subscription={{values: true}}>
                    {({formData, ...rest}) => {
                        if (formData.instrument) {
                            return <div style={{
                                backgroundColor: "rgba(0, 0, 0, 0.04)",
                                padding: "1em"
                            }}>{data ? findDocstring(formData.instrument, data).map(val =>
                                <p>{val}</p>) : null}</div>
                        } else {
                            return null;
                        }
                    }
                    }
                </FormDataConsumer>
                <FormDataConsumer subscription={{values: true}}>
                    {({formData, ...rest}) => {
                        if (formData.instrument) {
                            return (
                                <fieldset>
                                    <legend>Parameters</legend>
                                    {data ? findParams(formData.instrument, data).map(val => createInputElement(val)) : null}
                                </fieldset>);
                        } else {
                            return null;
                        }
                    }
                    }
                </FormDataConsumer>

                <DateTimeInput source={"dateTime"}
                               defaultValue={currentTimeStamp.toISOString()}
                               validate={required()} label={"Experiment creation date"}/>
            </SimpleForm>
        </Create>
    );

}