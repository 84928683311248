import {useGetList} from "ra-core";
import {Error, Loading, required, SelectInput, SelectInputProps} from "react-admin";
import React from "react";


type AvailableVariableFronted = {
    id: string, name: string
}


export const AvailableVariableInput = (props: JSX.IntrinsicAttributes & SelectInputProps & { link: string }) => {
    const {
        data: availableVariables,
        isLoading: loadingAvailableVariables,
        error: errorAvailableVariables
    } = useGetList<AvailableVariableFronted>('available_variables', {
        pagination: {
            page: 1,
            perPage: 1000
        },
        sort: {field: "", order: ""},
        filter : props.link
    });

    if (loadingAvailableVariables) return <Loading/>;
    if (errorAvailableVariables) return (<p>Could not load available variables</p>);
    return (<SelectInput choices={availableVariables}{...props} />);

}