import {backendUrl} from "../DataProvider/dataProvider";
import {string} from "prop-types";
import {PlotDescription} from "../Plots/ShowPlot";
import {useRecordContext} from "ra-core";
import {get} from "react-hook-form";

class ZipDownloadProps {
    readonly identifier:string

    constructor(identifier: string) {
        this.identifier = identifier;
    }
}

export const ZipDownload = (props: ZipDownloadProps) => {
    const record = useRecordContext(props);
    const value = get(record, props.identifier);
    console.log("'"+value+"'");
    const experimentId = value
    const url = backendUrl + "/experiment_files/" + experimentId
    console.log("Url for zip download : " + url)

    return (
        <a href={url} download={"testzip.zip"}>Download files as zip</a>
    );

}