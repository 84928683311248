import {Layout, LayoutProps, MenuItemLink, MenuProps} from 'react-admin';
import * as React from 'react';
import {FC} from 'react';
import ListIcon from '@mui/icons-material/List';
import BarChartIcon from '@mui/icons-material/BarChart';

const CustomMenu: FC<MenuProps> = props => {
    return (
        <React.Fragment>
            <MenuItemLink
                to="/experiments"
                primaryText="Experiments"
                leftIcon={<ListIcon/>}
            />
            <MenuItemLink
                to="/instruments"
                primaryText="Instruments"
                leftIcon={<ListIcon/>}
            />

            <MenuItemLink
                to="/plots"
                primaryText="Plots"
                leftIcon={<BarChartIcon/>}
            />


        </React.Fragment>
    );
}


const CustomLayout = (props: LayoutProps) => {

    return (<Layout
        {...props}
        menu={CustomMenu}
    />);
}

export default CustomLayout

