import {Show, ShowProps, SimpleShowLayout} from "react-admin";
import React from "react";

export const TestShow = (props: ShowProps) => {
    return (
        <Show title="Experiment Detail" {...props}>
            <SimpleShowLayout>

            </SimpleShowLayout>
        </Show>
    );
}