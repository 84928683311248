import React from 'react';
import {Datagrid, List, ListProps, TextField} from 'react-admin';

export const InstrumentsList = (props: ListProps) => (
    <List {...props} exporter={false}>
        <Datagrid rowClick="show">
            <TextField source="name"/>
            <TextField source="docString"/>
        </Datagrid>
    </List>
);