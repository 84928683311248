import * as React from "react";
import {Admin, CustomRoutes, Resource, Title} from 'react-admin';
import {ExperimentsList} from "./Experiments/list";
import {ExperimentsCreate} from "./Experiments/create";
import {InstrumentsCreate} from "./Instruments/create";
import {InstrumentsList} from "./Instruments/list";
import dataProvider from "./DataProvider/dataProvider";
import {InstrumentsShow} from "./Instruments/show";
import {ExperimentsShow} from "./Experiments/show";
import CustomLayout from "./CustomMenu/CustomLayout";
import {Route} from "react-router-dom";
import {PlotPage} from "./Plots/PlotPage";
import {ExperimentsEdit} from "./Experiments/edit";
import {TestList} from "./Test/list";
import {TestCreate} from "./Test/create";
import {TestShow} from "./Test/show";


const App = () => {
    return (<Admin dataProvider={dataProvider}
                   layout={CustomLayout}>
        <Resource name={"instruments"} list={InstrumentsList} create={InstrumentsCreate}
                  show={InstrumentsShow}/>
        <Resource name={"experiments"} list={ExperimentsList} create={ExperimentsCreate}
                  show={ExperimentsShow} edit={ExperimentsEdit}/>
        <Resource name={"available_variables"}/>
        <Resource name={"available_attributes"}/>
        <Resource name={"experiment_files"}/>
        <CustomRoutes >
            <Route path="/plots" element={<PlotPage />} />
        </CustomRoutes>
        <Resource name={"tests"} list={TestList} create={TestCreate}
                  show={TestShow} />
    </Admin>)
};

export default App;