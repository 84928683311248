import {Labeled, TextField, TextFieldProps} from "react-admin";
import React from "react";
import makeStyles from '@mui/styles/makeStyles';
import {useRecordContext} from "ra-core";

type StatusFieldParams =
    JSX.IntrinsicAttributes
    & TextFieldProps
    & { showLabel: boolean, source: string, label: string };
export const StatusField = (props: StatusFieldParams) => {
    const record = useRecordContext(props);
    const status = record ? record[props.source] : "NO_STATUS"
    const classes = makeStyles({
        done: {color: 'green'},
        failed: {color: 'red'},
        none: {
            color: 'blue'
        },
    })();
    if (status === 'DONE') {
        if (props.showLabel) {
            return (<Labeled label={props.label}><span
                className={classes.done}>{status} </span></Labeled>)
        } else {
            return <TextField className={classes.done} {...props} />;

        }
    }
    if (status === 'FAILED') {
        if (props.showLabel) {
            return (<Labeled label={props.label}><span
                className={classes.failed}>{status} </span></Labeled>)
        }
        return <TextField className={classes.failed} {...props} />;
    }
    return <TextField className={classes.none} {...props}/>;
}